<template>
  <img :src="imgUrl" alt="" style="width: 100%;height: 100%;" />
</template>
<script>
import Axios from "axios";
export default {
  props: {
    src: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      imgUrl: ""
    };
  },
  mounted() {
    if (this.src) {
      this.getImgUrl();
    }
  },
  methods: {
    getImgUrl() {
      const url = "https://dkatmweejckwm.cloudfront.net/" + this.src;
      Axios({
        method: "get",
        url,
        responseType: "arraybuffer"
      }).then(res => {
        this.imgUrl = this.imgEncrypt(res.data);
      });
    },
    imgEncrypt(buffer) {
      const bytes = new Uint8Array(buffer);
      const encoder = new TextEncoder();
      const KEY = encoder.encode("2019ysapp7527");
      for (let i = 0; i < 100; i++) {
        bytes[i] ^= KEY[i % KEY.length];
      }
      const blob = new Blob([bytes], {
        type: "image/png"
      });
      const url = URL.createObjectURL(blob);
      return url;
    }
  }
};
</script>
